<template>
  <q-page class="" padding>
    <on-demand-form
      :options="options"
      :location-query="placeSearch"
      :mapper="placeMapper"
      :user="user"
      :initial="initial"
      single
      :addresses="{saved, recent}"
      :no-return="true"
      check-out
      :labels="labels"
      type="fas fa-bed"
      @submit="onSubmit"
    />
  </q-page>
</template>

<script>
import date from 'utils/date-time'
import OnDemandForm from '../form/'
import { query as placeSearch } from 'api/places'
import { placeMapper } from '../form/mappers'
import { mapGetters } from 'vuex'
export default {
  components: {
    OnDemandForm
  },
  data () {
    return {
      labels: {
        outbound: {
          date: this.$t('check_in')
        },
        inbound: {
          date: this.$t('check_out')
        },
        submit: this.$tc('find.hotel')
      },
      initial: {
        departMin: date.newDate(),
        options: {
          adults: 1,
          children: 0,
          rooms: 1
        },
        date: {
          outbound: date.addToDate(date.newDate(), { minutes: 5 }),
          inbound: date.addToDate(date.newDate(), { minutes: 5, day: 1 })
        }
      },
      options: {
        adults: Array.from({ length: 10 }, (_, i) => ({ label: (i + 1).toString(), value: i + 1 }))
      },
      returning: true,
      locationResults: null,
      activeInput: ''
    }
  },
  computed: {
    ...mapGetters({
      user: 'userLookup',
      recent: 'addresses/recent',
      saved: 'addresses/saved'
    }),
    disabled () {
      if (!this.parameters.origin || !this.parameters.destination) {
        return true
      }
      return false
    },
    people: {
      get () {
        return {
          traveller: this.parameters.traveller,
          requester: this.parameters.requester
        }
      },
      set (val) {
        this.parameters.requester = val.requester
        this.parameters.traveller = val.traveller
      }
    }
  },
  methods: {
    placeSearch,
    placeMapper,
    onSubmit (values) {
      const { origin, date, rooms, adults, requester, traveller } = values
      const mapped = {
        location: origin,
        check_in: date.outbound,
        check_out: date.inbound,
        rooms,
        adults,
        requester,
        traveller
      }

      this.$store.dispatch('ondemand/stash', { parameters: mapped, hotelList: [] })
      this.$router.push({ name: 'ondemand-hotel-availability' })
    }
  }
}
</script>
